<template>
	<div class="institutional-portrait">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="企业名称">
					<el-autocomplete class="inline-input" v-model="formSearch.companyName" :fetch-suggestions="querySearch" placeholder="请输入企业名称" :trigger-on-focus="false" @select="handleSelect" clearable></el-autocomplete>
				</el-form-item>
				<el-form-item label="所属区域">
					<el-cascader v-model="formSearch.quyu" :options="quyuList" :props="quyuProps" ref="quyuRef" clearable change-on-select style="width: 100%;"></el-cascader>
				</el-form-item>
				<el-form-item label="行业">
					<el-cascader v-model="formSearch.industry" :options="industryList" :props="industryProps" ref="industryRef" clearable change-on-select style="width: 100%;"></el-cascader>
				</el-form-item>
				<el-form-item label="风险等级">
					<el-select v-model="formSearch.riskLevel" clearable>
						<el-option label="高风险" value="1"></el-option>
						<el-option label="中风险" value="2"></el-option>
						<el-option label="低风险" value="3"></el-option>
						<el-option label="监测中" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入库时间">
					<el-date-picker v-model="formSearch.timeRK" value-format="yyyy-MM-dd" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsRK"></el-date-picker>
				</el-form-item>
				<el-form-item label="审核状态">
					<el-select v-model="formSearch.checkStatus" clearable>
						<el-option label="未审核" value="0"></el-option>
						<el-option label="已审核" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="预警分数">
					<el-row>
						<el-col :span="11">
							<el-input v-model="formSearch.lowRisk" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" maxlength="2" placeholder="开始分数"></el-input>
						</el-col>
						<el-col :span="2" style="text-align: center;">
							-
						</el-col>
						<el-col :span="11">
							<el-input v-model="formSearch.highRisk" onkeyup="value=value.replace(/[^0-9]/g,'');if(value>100){value=100};if(String(value)==='0'){value=100}" maxlength="3" placeholder="结束分数"></el-input>
						</el-col>
					</el-row>
				</el-form-item>
				<el-form-item label="预警时间">
					<el-date-picker v-model="formSearch.timeYJ" value-format="yyyy-MM-dd" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsYJ"></el-date-picker>
				</el-form-item>
				<el-form-item label="立案状态">
					<el-select v-model="formSearch.caseStatus" clearable>
						<el-option label="未立案" value="0"></el-option>
						<el-option label="已立案" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="立案时间">
					<el-date-picker v-model="formSearch.timeLA" value-format="yyyy-MM-dd" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsLA"></el-date-picker>
				</el-form-item>
				<el-form-item label="风险点">
					<el-select v-model="formSearch.riskLabels" multiple clearable placeholder="请选择">
						<el-option
							v-for="(item,index) in formSearch.riskLabelsList"
							:key="index"
							:label="item.tagName"
							:value="item.tagName">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<h4 class="title-h4" style="float: left;">企业总数：<span>{{totalItems}}</span> 家</h4>
				<el-popover placement="bottom" width="80" trigger="click" style="float: right;">
					<div style="padding: 15px;box-sizing: border-box;">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
						<div style="margin: 10px 0;"></div>
						<el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="item in colSelect" :label="item" :key="item"></el-checkbox>
						</el-checkbox-group>
					</div>
					<el-button type="primary" size="mini" icon="iconfont icon-ic_detail" slot="reference" title="列表配置" style="padding: 4px 5px 2px 5px;margin-bottom:5px;"></el-button>
				</el-popover>
				<el-table border v-loading="loadingFlag" :data="tableData" @sort-change='sortChange' :default-sort="{prop: 'risk', order: 'descending'}" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="selection" width="30"></el-table-column>
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="companyName" label="企业名称" width="250" :show-overflow-tooltip="true" v-if="colData[0].istrue">
						<template slot-scope="scope">
							<span @click="companyNameLink(scope.row)" class="color-m" style="cursor: pointer;">{{scope.row.companyName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true" v-if="colData[1].istrue"></el-table-column>
					<el-table-column prop="industry" label="行业" :show-overflow-tooltip="true" v-if="colData[2].istrue"></el-table-column>
					<el-table-column prop="earlyWarningScore" label="机器预警指数" sortable="custom" :show-overflow-tooltip="true" v-if="colData[3].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.earlyWarningScore==null">-</span>
							<span v-else>{{scope.row.earlyWarningScore}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="sysEarlyWarningTime" label="机器预警时间" sortable="custom" :show-overflow-tooltip="true" v-if="colData[4].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.sysEarlyWarningTime==null">-</span>
							<span v-else>{{scope.row.sysEarlyWarningTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="risk" label="预警指数" sortable="custom" :show-overflow-tooltip="true" v-if="colData[5].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.risk==null">-</span>
							<span v-else>{{scope.row.risk}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="earlyWarningTime" label="预警时间" sortable="custom" :show-overflow-tooltip="true" v-if="colData[6].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.earlyWarningTime==null">-</span>
							<span v-else>{{scope.row.earlyWarningTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="riskLevel" label="风险等级" :show-overflow-tooltip="true" v-if="colData[7].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.riskLevel == '0'">监测中</span>
							<span v-if="scope.row.riskLevel == '1'">高风险</span>
							<span v-if="scope.row.riskLevel == '2'">中风险</span>
							<span v-if="scope.row.riskLevel == '3'">低风险</span>
						</template>
					</el-table-column>
					<el-table-column prop="manualModifyLevel" label="人工干预风险等级" :show-overflow-tooltip="true" v-if="colData[8].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.manualModifyLevel == '-1'">-</span>
							<span v-if="scope.row.manualModifyLevel == '0'">监测中</span>
							<span v-if="scope.row.manualModifyLevel == '1'">高风险</span>
							<span v-if="scope.row.manualModifyLevel == '2'">中风险</span>
							<span v-if="scope.row.manualModifyLevel == '3'">低风险</span>
						</template>
					</el-table-column>
					<el-table-column prop="manualModifyScore" label="人工干预风险指数" sortable="custom" :show-overflow-tooltip="true" v-if="colData[9].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.manualModifyScore==null">-</span>
							<span v-else>{{scope.row.manualModifyScore}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="riskLabels" label="风险点" :show-overflow-tooltip="true" v-if="colData[10].istrue"></el-table-column>
					<el-table-column prop="regStatus" label="经营状态" :show-overflow-tooltip="true" v-if="colData[11].istrue"></el-table-column>
					<el-table-column prop="regCapital" label="注册资本" :show-overflow-tooltip="true" v-if="colData[12].istrue"></el-table-column>
					<el-table-column prop="checkStatus" label="审核状态" :show-overflow-tooltip="true" v-if="colData[13].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.checkStatus == '0'">未审核</span>
							<span v-if="scope.row.checkStatus == '1'">已审核</span>
						</template>
					</el-table-column>
					<el-table-column prop="checkStatusTime" label="审核时间" sortable="custom" :sort-by="['checkStatusTime', 'id']" :show-overflow-tooltip="true" v-if="colData[14].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.checkStatusTime==null">-</span>
							<span v-else>{{scope.row.checkStatusTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="caseStatus" label="立案状态" :show-overflow-tooltip="true" v-if="colData[15].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.caseStatus == '0'">未立案</span>
							<span v-if="scope.row.caseStatus == '1'">已立案</span>
						</template>
					</el-table-column>
					<el-table-column prop="earlyWarningFilingTime" label="立案时间" sortable="custom" :show-overflow-tooltip="true" v-if="colData[16].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.earlyWarningFilingTime==null">-</span>
							<span v-else>{{scope.row.earlyWarningFilingTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="insertTime" label="入库时间" sortable="custom" :show-overflow-tooltip="true" v-if="colData[17].istrue">
						<template slot-scope="scope">
							<span v-if="scope.row.insertTime==null">-</span>
							<span v-else>{{scope.row.insertTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="操作" width="50">
						<template slot-scope="scope">
							<i class="iconfont icon-bianji color-m" title="编辑" @click="handleEditBJ(scope.$index, scope.row)" style="cursor: pointer;"></i>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		<!-- 编辑   弹框 -->
		<el-dialog title="操作" :visible.sync="dialogVisibleBJ" width="30%">
			<el-form :model="ruleFormBJ" ref="ruleFormBJ" label-width="130px" class="demo-ruleForm">
				<el-form-item label="人工干预风险等级" prop="manualModifyLevel">
					<el-select v-model="ruleFormBJ.manualModifyLevel">
						<el-option label="未干预" :value="-1"></el-option>
						<el-option label="监测中" :value="0"></el-option>
						<el-option label="高风险" :value="1"></el-option>
						<el-option label="中风险" :value="2"></el-option>
						<el-option label="低风险" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="人工干预风险指数" prop="manualModifyScore">
					<el-input v-model="ruleFormBJ.manualModifyScore" placeholder="请输入人工干预风险指数"></el-input>
				</el-form-item>
				<el-form-item label="立案状态" prop="caseStatus">
					<el-select v-model="ruleFormBJ.caseStatus">
						<el-option label="是" :value="1">是</el-option>
						<el-option label="否" :value="0">否</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="立案时间" prop="earlyWarningFilingTime">
					<el-date-picker v-model="ruleFormBJ.earlyWarningFilingTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFormBJ('ruleFormBJ')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {exportExcel} from '../../provider/index.js' //导出Excel公用方法
export default {
	name: 'institutional-portrait',
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			//省市区  层级选择器
			quyuList: [], //省市区   数据
			quyuProps: {
				lazy: true,
			},
			industryList: [], //行业   数据
			industryProps: {
				value: 'id',
				label: 'name',
				children: 'children'
			},
			//预警时间
			pickerOptionsYJ: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			//预警时间
			pickerOptionsLA: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			//入库时间
			pickerOptionsRK: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch: {
				companyName: '',
				quyu: [],
				riskLevel: '',
				industry: [],
				timeYJ: null,
				checkStatus: '',
				timeLA: null,
				timeRK: null,
				caseStatus: '',
				riskLabels:[],
				riskLabelsList:[],
				lowRisk: '',
				highRisk: '',
				prop: 'risk',
				order: 'desc',
			},
			loadingFlag: false,
			dataTableEmpty: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			//列表配置  全选
			isIndeterminate: true,
			checkAll: false,
			//列表配置  列数据
			colData: [{
					istrue: true,
					title: "企业名称",
					name: 'companyName'
				},
				{
					istrue: true,
					title: "所属区域",
					name: 'area'
				},
				{
					istrue: true,
					title: "行业",
					name: 'industry'
				},
				{
					istrue: false,
					title: "机器预警指数",
					name: 'earlyWaringScore'
				},
				{
					istrue: false,
					title: "机器预警时间",
					name: 'sysEarlyWarningTime'
				},
				{
					istrue: true,
					title: "预警指数",
					name: 'risk'
				},
				{
					istrue: true,
					title: "预警时间",
					name: 'earlyWarningTime'
				},
				{
					istrue: true,
					title: "风险等级",
					name: 'riskLevel'
				},
				{
					istrue: false,
					title: "人工干预风险等级",
					name: 'manualModifyLevel'
				},
				{
					istrue: false,
					title: "人工干预风险指数",
					name: 'manualModifyScore'
				},
				{
					istrue: true,
					title: "风险点",
					name: 'riskLabels'
				},
				{
					istrue: false,
					title: "经营状态",
					name: 'regStatus'
				},
				{
					istrue: false,
					title: "注册资本",
					name: 'regCapital'
				},
				{
					istrue: true,
					title: "审核状态",
					name: 'checkStatus'
				},
				{
					istrue: false,
					title: "审核时间",
					name: 'checkStatusTime'
				},
				{
					istrue: true,
					title: "立案状态",
					name: 'caseStatus'
				},
				{
					istrue: true,
					title: "立案时间",
					name: 'earlyWarningFilingTime'
				},
				{
					istrue: true,
					title: "入库时间",
					name: 'insertTime'
				},
			],
			colOptions: [],
			colSelect: [],
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName: "导出所选",
			exportAllName: "导出全部",
			dialogVisibleBJ: false, //是否打开编辑弹框
			ruleFormBJ: {
				companyId: '',
				manualModifyLevel: '',
				manualModifyScore: '',
				caseStatus: '',
				earlyWarningFilingTime: '',
			},
		}
	},
	created() {
		let _this = this; //this指向   重定义
		//省市区  层级选择器
		this.quyuProps.lazyLoad = function lazyLoad(node, resolve) {
			//根据用户信息判断 显示数据
			let levelInit = 0;
			/* if (JSON.parse(sessionStorage.getItem("user_xjfj")).level == "0") {
				levelInit = 0;
			} else if (JSON.parse(sessionStorage.getItem("user_xjfj")).level == "1") {
				levelInit = JSON.parse(sessionStorage.getItem("user_xjfj")).provinceId;
			} else if (JSON.parse(sessionStorage.getItem("user_xjfj")).level == "2") {
				levelInit = JSON.parse(sessionStorage.getItem("user_xjfj")).cityId;
			} */
			//所对应节点数据
			const { level } = node;
			//获取下一节点数据
			setTimeout(() => {
				_this.$provider.get('/wg-ifrRisk/monitor/getAreaList', {
					params: {
						parentId: level == 0 ? levelInit : node.value
					}
				}).then(res => {
					const nodes = [];
					for (let i = 0; i < res.data.length; i++) {
						nodes.push({
							label: res.data[i].name,
							value: res.data[i].code,
							leaf: level >= 2
						});
					}
					// 通过调用resolve将子节点数据返回，通知组件数据加载完成
					resolve(nodes);
				})
			}, 500);
		}
		this.getCompanyIndustry(); //加载行业
		this.getRiskLabelList();//加载风险点  检索

		//监控 表格  初始  显示列
		for (let i = 0; i < this.colData.length; i++) {
			this.colSelect.push(this.colData[i].title);
			if (this.colData[i].istrue == true) {
				this.colOptions.push(this.colData[i].title);
			}
		}
		this.getPageList(); //加载列表
	},
	mounted() {},
	watch: {
		//表格控制列显隐
		colOptions(valArr) {
			var arr = this.colSelect.filter(i => valArr.indexOf(i) < 0); // 未选中
			this.colData.filter(i => {
				if (arr.indexOf(i.title) != -1) {
					i.istrue = false;
				} else {
					i.istrue = true;
				}
			});
		}
	},
	methods: {
		//模糊提示 功能
		querySearch(queryString, cb) {
			this.$provider.get('/wg-ifrRisk/monitor/getMatchCompanyNameList', {
				params: {
					companyName: this.formSearch.companyName
				}
			}).then(res => {
				let restaurants = [];
				res.data.filter((item, index) => {
					restaurants.push({
						value: item.companyName,
						id: item.id
					})
				})
				cb(restaurants); // 调用 callback 返回建议列表的数据
				/* let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;//确定是否为首字
				cb(results); // 调用 callback 返回建议列表的数据*/
			})
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		handleSelect(item) {
			this.formSearch.companyName = item.value; //选中提示   填入
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},

		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			let paramStr = this.getParams();
			if (paramStr.lowRisk <= paramStr.highRisk) {
				this.loadingFlag = true; //loading 显示
				this.dataTableEmpty = false;
				this.$provider.get('/wg-ifrRisk/monitor/getList', {
					params: {
						companyName: paramStr.companyName.replace(/^\s+|\s+$/g, ""),
						provinceId: paramStr.provinceId,
						cityId: paramStr.cityId,
						districtId: paramStr.districtId,
						riskLevel: paramStr.riskLevel,
						firstIndustryId: paramStr.firstIndustryId,
						secondIndustryId: paramStr.secondIndustryId,
						earlyWarningTimeStart: paramStr.earlyWarningTimeStart,
						earlyWarningTimeEnd: paramStr.earlyWarningTimeEnd,
						checkStatus: paramStr.checkStatus,
						earlyWarningFilingTimeStart: paramStr.earlyWarningFilingTimeStart,
						earlyWarningFilingTimeEnd: paramStr.earlyWarningFilingTimeEnd,
						insertTimeStart: paramStr.insertTimeStart,
						insertTimeEnd: paramStr.insertTimeEnd,
						caseStatus: paramStr.caseStatus,
						riskLabels: paramStr.riskLabels,
						lowRisk: paramStr.lowRisk,
						highRisk: paramStr.highRisk,
						prop: paramStr.prop,
						order: paramStr.order,
						current: this.currentPage,
						size: this.pageSize,
					}
				}).then(res => {
					this.loadingFlag = false; //loading 消失
					this.dataTableEmpty = true;
					this.tableData = res.data.records;
					this.totalItems = res.data.total;
				})
			} else {
				this.$message({
					message: "预警开始分数需小于预警结束分数，请重新输入检索",
					type: 'error'
				});
			}
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//排序
		sortChange(column, prop, order) {
			if (column.order == "ascending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "asc";
			} else if (column.order == "descending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "desc";
			} else {
				this.formSearch.prop = "";
				this.formSearch.order = "";
			}
			this.getPageList(); //加载列表
		},
		//点击机构   跳转详情页
		companyNameLink(item) {
			const icardUrl = this.$router.resolve({
				path: '/institutionalPortraitInfo',
				query: {
					name: item.companyName,
					id: item.id
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},

		//编辑  弹框打开
		handleEditBJ(index, result) {
			this.dialogVisibleBJ = true; //打开弹框
			this.ruleFormBJ.companyId = result.id; //id
			this.ruleFormBJ.manualModifyLevel = result.manualModifyLevel; //风险等级
			this.ruleFormBJ.manualModifyScore = result.manualModifyScore; //指数
			this.ruleFormBJ.caseStatus = result.caseStatus; //立案状态
			this.ruleFormBJ.earlyWarningFilingTime = result.earlyWarningFilingTime; //立案时间
		},
		//编辑  提交
		submitFormBJ(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$provider.post('/wg-ifrRisk/monitor/auditWarn', {
						id: this.ruleFormBJ.companyId,
						manualModifyLevel: this.ruleFormBJ.manualModifyLevel,
						manualModifyScore: this.ruleFormBJ.manualModifyScore,
						caseStatus: this.ruleFormBJ.caseStatus,
						earlyWarningFilingTime: this.ruleFormBJ.earlyWarningFilingTime,
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '编辑成功',
								type: 'success'
							});
							this.dialogVisibleBJ = false; //关闭弹框
							this.getPageList(); //加载列表
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
							return false;
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		//列表配置   全选
		handleCheckAllChange(val) {
			this.colOptions = val ? this.colSelect : [];
			this.isIndeterminate = false;
		},
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.colSelect.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length;
		},

		//表格 每行  复选框选值    导出
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let paramStr = this.getParams();
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr + '/wg-ifrRisk/monitor/exportSelect',
					fileName: '监测预警企业',
					params: 'companyIds=' + chk_value + '&rowType=' + paramStr.rowType
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj, this); // 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
				return false;
			}
		},
		//导出全部
		exportALLFn() {
			let paramStr = this.getParams();
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr + '/wg-ifrRisk/monitor/exportAll',
				fileName: '监测预警企业',
				params: 'companyName='+paramStr.companyName+'&provinceId='+paramStr.provinceId+'&cityId='+paramStr.cityId+'&districtId='+paramStr.districtId+'&riskLevel='+paramStr.riskLevel+'&firstIndustryId='+paramStr.firstIndustryId+'&secondIndustryId='+paramStr.secondIndustryId+'&earlyWarningTimeStart='+paramStr.earlyWarningTimeStart+'&earlyWarningTimeEnd='+paramStr.earlyWarningTimeEnd+'&checkStatus='+paramStr.checkStatus+'&earlyWarningFilingTimeStart='+paramStr.earlyWarningFilingTimeStart+'&earlyWarningFilingTimeEnd='+paramStr.earlyWarningFilingTimeEnd+'&insertTimeStart='+paramStr.insertTimeStart+'&insertTimeEnd='+paramStr.insertTimeEnd+'&caseStatus='+paramStr.caseStatus+'&riskLabels='+paramStr.riskLabels+'&prop='+paramStr.prop+'&order='+paramStr.order+'&lowRisk='+paramStr.lowRisk+'&highRisk='+paramStr.highRisk+'&rowType='+paramStr.rowType
			}
			this.exportAllName = "正在导出中...";
			exportExcel(myObj, this); // 导出Excel公用方法
		},

		//加载行业  检索
		getCompanyIndustry() {
			this.$provider.get('/wg-ifrRisk/monitor/getIndustryList', {}).then(res => {
				this.industryList = res.data;
			})
		},
		//加载风险点  检索
		getRiskLabelList() {
			this.$provider.get('/wg-ifrRisk/monitor/getRiskLabelList', {}).then(res => {
				this.formSearch.riskLabelsList = res.data;
			})
		},

		//获取参数
		getParams() {
			let checkStatus, caseStatus;
			if (this.formSearch.checkStatus != "") {
				checkStatus = Number(this.formSearch.checkStatus);
			} else {
				checkStatus = "";
			}
			if (this.formSearch.caseStatus != "") {
				caseStatus = Number(this.formSearch.caseStatus);
			} else {
				caseStatus = "";
			}
			//let quyuArr = this.formSearch.quyu.length!=0 ? this.$refs.quyuRef.getCheckedNodes()[0].pathLabels : [];
			let quyuArr = this.formSearch.quyu;
			let rowCol = "";
			this.colData.forEach(function(f, i) {
				if (f.istrue) {
					if (i == 0) {
						rowCol = f.name;
					} else {
						rowCol += "," + f.name;
					}
				}
			})
			let obj = {
				companyName: this.formSearch.companyName,
				provinceId: quyuArr.length >= 1 ? quyuArr[0] : "",
				cityId: quyuArr.length >= 2 ? quyuArr[1] : "",
				districtId: quyuArr.length >= 3 ? quyuArr[2] : "",
				riskLevel: this.formSearch.riskLevel != "" ? this.formSearch.riskLevel : "",
				firstIndustryId: this.formSearch.industry.length == 1 || this.formSearch.industry.length == 2 ? this.formSearch.industry[0] : "",
				secondIndustryId: this.formSearch.industry.length == 2 ? this.formSearch.industry[1] : "",
				earlyWarningTimeStart: this.formSearch.timeYJ != null ? this.formSearch.timeYJ[0] : "",
				earlyWarningTimeEnd: this.formSearch.timeYJ != null ? this.formSearch.timeYJ[1] : "",
				checkStatus: checkStatus,
				earlyWarningFilingTimeStart: this.formSearch.timeLA != null ? this.formSearch.timeLA[0] : "",
				earlyWarningFilingTimeEnd: this.formSearch.timeLA != null ? this.formSearch.timeLA[1] : "",
				insertTimeStart: this.formSearch.timeRK != null ? this.formSearch.timeRK[0] : "",
				insertTimeEnd: this.formSearch.timeRK != null ? this.formSearch.timeRK[1] : "",
				caseStatus: caseStatus,
				riskLabels: this.formSearch.riskLabels.length!=0 ? this.formSearch.riskLabels.join(",") : "",
				lowRisk: this.formSearch.lowRisk != '' ? Number(this.formSearch.lowRisk) : 0,
				highRisk: this.formSearch.highRisk != '' ? Number(this.formSearch.highRisk) : 100,
				prop: this.formSearch.prop,
				order: this.formSearch.order,
				rowType: rowCol
			}
			return obj;
		}
	},
}
</script>

<style lang="stylus" scoped="scoped"></style>
